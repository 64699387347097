import request from 'src/service/request';

export function test() {
  return request({
    url: '/UserMgrApi/IOT/GetString?count=10&nDepartmentID=41227616372426752&cCode=HMCF01',
    token: 'humuyun',
    options: {
      loading: true
    }
  });
}

export default {};
