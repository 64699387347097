import { lazy } from 'react';


// export const Home = lazy(() =>
//   import(/* webpackChunkName: "shop" */ 'src/views/global/Shop/home')
// ); // 首页
export const ProductDetail = lazy(() =>
  import(/* webpackChunkName: "productDetail" */ 'src/views/global/Shop/shopping/ProductDetail')
); // 产品详情
export const storeList = lazy(() =>
  import(/* webpackChunkName: "storeList" */ 'src/views/global/Shop/shopping/store/storeList')
); // 体验店列表
export const storeDetail = lazy(() =>
  import(/* webpackChunkName: "storeDetail" */ 'src/views/global/Shop/shopping/store/storeDetail')
); // 体验店详情
export const Shop = lazy(() =>
  import(/* webpackChunkName: "shopHome" */ 'src/views/global/Shop/shopping')
); // 首页
export const SubmitOrder = lazy(() =>
  import(/* webpackChunkName: "submitOrder" */ 'src/views/global/Shop/shopping/SubmitOrder')
); // 提交订单
export const OrderPay = lazy(() =>
  import(/* webpackChunkName: "OrderPay" */ 'src/views/global/Shop/shopping/OrderPay')
); // 订单付款
export const PayResult = lazy(() =>
  import(/* webpackChunkName: "PayResult" */ 'src/views/global/Shop/shopping/PayResult')
); // 订单付款结果
export const User = lazy(() =>
  import(/* webpackChunkName: "ShopUser" */ 'src/views/global/Shop/user')
); // 我的页面
export const ReceiptAddress = lazy(() =>
  import(/* webpackChunkName: "ReceiptAddress" */ 'src/views/global/Shop/user/ReceiptAddress')
); // 收货地址
export const ReceiptAddressCreate = lazy(() =>
  import(/* webpackChunkName: "ReceiptAddressCreate" */ 'src/views/global/Shop/user/ReceiptAddress/addAddress')
); // 添加收货地址
export const MyOrder = lazy(() =>
  import(/* webpackChunkName: "ShopUserMyOrder" */ 'src/views/global/Shop/user/MyOrder')
); // 订单列表页面
export const OrderDetail = lazy(() =>
  import(/* webpackChunkName: "PayResult" */ 'src/views/global/Shop/user/MyOrder/OrderDetail')
); // 订单详情


const AlotCenterStoreRouter = {
  '/shop': Shop,
  '/shop/ProductDetail/:id/:name': ProductDetail,
  '/shop/storeList': storeList,
  '/shop/storeDetail/:id': storeDetail,
  '/shop/SubmitOrder': SubmitOrder,
  '/shop/OrderPay': OrderPay,
  '/shop/PayResult': PayResult,
  '/shop/User': User,
  '/shop/ReceiptAddress': ReceiptAddress,
  '/shop/ReceiptAddressCreate': ReceiptAddressCreate,
  '/shop/User/MyOrder': MyOrder,
  '/shop/User/OrderDetail': OrderDetail,
};
export default AlotCenterStoreRouter;
