import { Toast } from 'antd-mobile';
import { useEffect, useState } from 'react';
import Request from 'src/service/request';
import { getStorage, getUrlParams, setStorage } from 'src/utils/utils';
/**
 *
 *
 * @export
 * @return {*}  3.0授权返回code监听
 * 原因是微信只能白名单两个 所以授权逻辑放在3.0里面 具体可访问
 */
export default function UseWatchToken() {
  const [currentCallBack, setCurrentCallBack] = useState(false); // 是否可以渲染路由
  useEffect(() => {
    // 没有token判断是正常页面 还是需要token
    if (
      !getStorage('tokenUserMgr') ||
      !getStorage('tokenInvoicing') ||
      !getStorage('tokenHumuyun')
    ) {
      // 判断当前路径是否有code 方便微信授权登录
      const url = window.location.href;
      const resultUrl = getUrlParams(url, 1); // 截取传递参数
      const { nDepartmentID, code } = resultUrl;
      if (!nDepartmentID || !code) {
        setCurrentCallBack(true);
        return;
      }
      const params = {
        cAppID: 'Humuyun,Invoicing',
        code,
        nDepartmentID
      };

      Request({
        url: '/UserMgrPublicApi/Token/WeChatCodeLogin',
        method: 'post',
        data: params
      }).then(ret => {
        if (ret && ret.ok) {
          const invoicingRes = ret.other.invoicing;
          const humuyunRes = ret.other.humuyun;
          setStorage('hmy_user', humuyunRes);
          setStorage('tokenUserMgr', invoicingRes.access_usermgr_token);
          setStorage('tokenHumuyun', humuyunRes.access_token);
          setStorage('tokenInvoicing', invoicingRes.access_token);
          setCurrentCallBack(true);
        } else {
          Toast.show({
            icon: 'fail',
            content: ret.msg || '获取失败'
          });
        }
      });
    } else {
      setCurrentCallBack(true);
    }
  }, []);

  return currentCallBack;
}
