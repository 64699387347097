const initState = {
  chartOptions: {
    chart: {
      type: 'area',
      height: '250'
    },
    title: {
      text: '',
      enabled: false
    },
    xAxis: {
      categories: [],
      tickmarkPlacement: 'on',
      title: {
        enabled: false
      }
    },
    yAxis: {
      title: {
        enabled: false
      }
    },
    legend: {
      enabled: false
    },
    tooltip: {
      pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
      shared: true,
      valueSuffix: ''
    },
    series: [
      {
        name: '',
        data: []
      }
    ]
  },
  chartMapOptions: {
    chart: {
      // map: 'cn/china',
      zoomType: 'x',
      resetZoomButton: {
        theme: {
          states: false
        }
      }
    },
    title: {
      text: '各地行情'
    },
    mapNavigation: {
      enabled: false,
      buttonOptions: {
        verticalAlign: 'bottom'
      }
    },
    tooltip: {
      useHTML: true,
      headerFormat: '<table>',
      pointFormat:
        '<tr><td>{point.name} {point.cType}</td><td></td></tr><tr><td>单价:</td><td>{point.value} {point.cUnit}</td></tr>',
      footerFormat: '</table>'
    },
    colorAxis: {
      min: 0,
      tickInterval: 0.2,
      minColor: '#fff',
      maxColor: '#006cee',
      labels: {
        style: {
          color: 'red',
          fontWeight: 'bold'
        }
      }
    },
    series: [
      {
        data: [],
        mapData: [],
        joinBy: 'name',
        name: '全国',
        states: {
          hover: {
            color: '#a4edba'
          }
        },
        dataLabels: {
          enabled: true,
          format: '{point.name} {point.value}',
          style: { fontSize: '9px' }
        }
      }
    ],
    drilldown: {
      activeDataLabelStyle: {
        color: '#FFFFFF',
        textDecoration: 'none',
        textShadow: '0 0 1px #373648'
      },
      drillUpButton: {
        relativeTo: 'spacingBox',
        position: {
          x: 0,
          y: 60
        }
      }
    }
  }
};

export default initState;
