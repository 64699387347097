import axios from 'axios';
import { Toast } from 'antd-mobile';
import { SwitchDep } from 'src/store/global/globalApi';
import { getStorage, setToken } from 'src/utils/utils';
import EventBus from 'src/utils/EventBus';
import getTokenAUTH from './auth';
import { BASEURL, TIMEOUT } from './config';
import { addPending, removePending, closeLoading, httpErrorStatusHandle } from './requestUtils';

const pendingMap = new Map(); // 存放请求和请求对应的取消函数的map
const LoadingInstance = {
  $target: null, // 保存Loading实例
  $count: 0
};
/**
 * 请求的基础函数
 * @param {axios相关配置项} axiosConfig
 * {
 *    url: '/xx/xx/xx',
 *    method:'get',  // 默认get方法
 *    token  // 可选值
 *    options:{}   // 自定义配置型
 * }
 * @param {自定义配置型} axiosConfig.options
 * {
 *  repeat_request_cancel: true, // 是否开启可以取消重复请求, 默认为 true
 *  error_message_show: true, // 是否开启接口错误信息展示，默认为true
 *  reduct_data_format: true, // 是否开启简洁的数据结构响应, 默认为true
 *  code_message_show: false, // 是否开启code不为0时的信息提示, 默认为false
 *  loading: false, // 是否开启loading层效果, 默认为false
 * }
 * @returns
 */
function IAxios(axiosConfig = { options: {} }) {
  // 自定义配置
  const mergeCustomOptions = {
    repeat_request_cancel: true,
    error_message_show: true,
    reduct_data_format: true,
    code_message_show: false,
    loading: false,
    ...axiosConfig.options
  };

  const service = axios.create({
    baseURL: BASEURL, // 设置统一的请求前缀
    timeout: TIMEOUT // 设置统一的超时时长
  });

  // 请求拦截器
  service.interceptors.request.use(
    config => {
      removePending(config, pendingMap);
      // 允许取消重复请求的情况下，将请求加入映射表
      mergeCustomOptions.repeat_request_cancel && addPending(config, pendingMap);
      // 创建loading实例
      if (mergeCustomOptions.loading) {
        LoadingInstance.$count += 1;
        if (LoadingInstance.$count === 1) {
          LoadingInstance.$target = Toast.show({
            content: '请耐心等待, 不要退出',
            maskClickable: false,
            duration: 0,
            icon: 'loading',
          });
        }
      }
      const token = getTokenAUTH(config.token);
      if (token && typeof window !== 'undefined') {
        config.headers.Authorization = token;
      }
      return config;
    },
    error => Promise.reject(error)
  );

  // 响应拦截器
  service.interceptors.response.use(
    response => {
      mergeCustomOptions.loading && closeLoading(mergeCustomOptions, LoadingInstance); // 关闭loading
      // 处理非http状态码错误
      if (mergeCustomOptions.code_message_show && response.data && response.data.code !== 200) {
        Toast.show({
          icon: 'fail',
          content: response.data.msg
        });
        // 登录过期，重新登录
        if (response.data.code === 401) {
          const hmyUser = getStorage('hmy_user');
          if (hmyUser && hmyUser.account && hmyUser.cPwd && hmyUser.departmentID) {
            SwitchDep(hmyUser.account, hmyUser.cPwd, hmyUser.departmentID).then(ret => {
              // 登录成功或者失败后的逻辑 做在具体页面
              if (ret && ret.ok) {
                setToken(ret.token);
                // document.location.reload();
                EventBus.emit('loginStatus', { status: true });
              } else {
                EventBus.emit('loginStatus', { status: false });
              }
            });
          }
        }
        return Promise.reject(response.data); // code不等于0
      }
      return mergeCustomOptions.reduct_data_format ? response.data : response;
    },
    error => {
      // 登录过期，重新登录
      if (error && error.response && error.response.status === 401) {
        const hmyUser = getStorage('hmy_user');
        if (hmyUser && hmyUser.account && hmyUser.cPwd && hmyUser.departmentID) {
          SwitchDep(hmyUser.account, hmyUser.cPwd, hmyUser.departmentID).then(ret => {
            // 登录成功或者失败后的逻辑 做在具体页面
            if (ret && ret.ok) {
              setToken(ret.token);
              // document.location.reload();
              EventBus.emit('loginStatus', { status: true });
            } else {
              EventBus.emit('loginStatus', { status: false });
            }
          });
        }
      }
      mergeCustomOptions.loading && closeLoading(mergeCustomOptions, LoadingInstance); // 关闭loading
      mergeCustomOptions.error_message_show && httpErrorStatusHandle(error); // 处理错误状态码
      return Promise.reject(error); // 错误继续返回给到具体页面
    }
  );

  return service(axiosConfig);
}

export default IAxios;
