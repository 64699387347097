import { createSlice } from '@reduxjs/toolkit';
import initState from './state';

export const slice = createSlice({
  name: 'price',
  initialState: initState,
  reducers: {
    setChartOptions: (
      state,
      { payload: { name = '', data = [], categories = [], valueSuffix = '' } }
    ) => {
      const { xAxis, tooltip } = state.chartOptions;
      xAxis.categories = categories;
      tooltip.valueSuffix = valueSuffix;
      state.chartOptions = { ...state.chartOptions, series: [{ name, data }], xAxis, tooltip };
    },
    setChartMapOptions: (state, { payload: { data = [], mapData = {} } }) => {
      const { series } = state.chartMapOptions;
      if (series.length > 0) {
        series[0].data = data;
        series[0].mapData = mapData;
      }
      state.chartMapOptions = { ...state.chartMapOptions, series };
    }
  }
});

export const { setChartOptions, setChartMapOptions } = slice.actions;

export default slice.reducer;
