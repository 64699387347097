import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import App from './App';
import './assets/style/css/normal.css';
import './assets/style/css/theme.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
console.log(process.env.HUMU_BASE_URL, '111111111111111111111111');

root.render(
  <HashRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </HashRouter>
);
