import './App.scss';
import UseWatchToken from './hooks/useWatchToken';
import ProjectRouter from './router';

function App() {
  const status = UseWatchToken(); // 全局监听 判断是否是3.0授权回来的
  return <div className='app'>{status && ProjectRouter()}</div>;
}

export default App;
