import { Toast } from 'antd-mobile';
import { BASEURL } from 'src/service/config';
import XEUtils from 'xe-utils';

/**
 * 复制剪贴板
 * @param {*} value
 */
function clipboard(value) {
  document.designMode = 'on';
  const input = document.createElement('input');
  document.body.appendChild(input);
  input.setAttribute('value', value);
  input.setAttribute('readonly', 'readonly');
  input.select();
  try {
    const successful = document.execCommand('copy');
    if (!successful) {
      throw new Error('copy failed');
    }
    Toast.show({
      content: '复制链接成功！',
      duration: 2000,
      position: 'top'
    });
  } catch (err) {
    // console.log(err);
    Toast.show({
      content: '复制链接失败，请重试或手动复制',
      duration: 2000,
      position: 'top'
    });
  }
  document.body.removeChild(input);
  document.designMode = 'off';
}
/**
 * 设置缓存
 * @param {*} key
 * @param {*} value
 */
function setStorage(key, value) {
  let v = value;
  if (typeof v === 'object') {
    v = JSON.stringify(v);
    v = `obj-${v}`;
  } else {
    v = `str-${v}`;
  }
  localStorage.setItem(key, v);
}
/**
 * 获取缓存
 * @param {*} key
 * @returns
 */
function getStorage(key) {
  let v = localStorage.getItem(key);
  if (!v) {
    return '';
  }
  if (v.indexOf('obj-') === 0) {
    v = v.slice(4);
    return JSON.parse(v);
  }
  if (v.indexOf('str-') === 0) {
    return v.slice(4);
  }
  return '';
}

/**
 * 设置token
 * @param {*} token
 */
function setToken(token) {
  setStorage('hmy_user', token.humuyun);
  setStorage('tokenUserMgr', token.humuyun.access_usermgr_token);
  setStorage('tokenHumuyun', token.humuyun.access_token);
  setStorage('tokenInvoicing', token.humuyun.access_Invoicing_token);
}
/**
 * 判断是否apicloud
 * @returns
 */
function isApi() {
  return typeof api !== 'undefined';
}
/**
 * 发送事件 apicloud APP 监听web的点击请求
 * @param {*} name 事件名称
 * @param {*} extra 发送内容
 */
function sendEvent(name, extra) {
  if (isApi()) {
    // eslint-disable-next-line no-undef
    api.sendEvent({
      name,
      extra: {
        ...extra
      }
    });
  }
}
/**
 * 获取图片网络路径
 * @param {*} path 路径
 * @returns
 */
function getImg(path) {
  return `${BASEURL}/UserMgr/files/image?name=${path}`;
}

// 获取阿里云静态资源
function getAliyunImg(url) {
  return `https://humuyun.oss-cn-beijing.aliyuncs.com/${url}`;
}

/** 对象转url参数 */
function objectToUrlParam(obj = {}) {
  let ret = '';
  const keys = Object.keys(obj);
  keys.forEach(el => {
    if (obj[el] && Object.prototype.hasOwnProperty.call(obj, el)) {
      ret += `${el}=${obj[el]}&`;
    }
  });
  if (ret) {
    ret = ret.substr(0, ret.length - 1);
  }
  return ret;
}
// 数字金额分割符
function numFormat(num, digits) {
  num = XEUtils.toNumber(num);
  if (!num) {
    return '';
  }
  const ret = XEUtils.commafy(num, { digits: digits || 2 });
  return ret;
}
/**
 * 保留几位小数
 * @param {*} num 值
 * @param {*} count 几位小数
 * @returns
 */
function toFixed(num, count) {
  if (count) {
    return XEUtils.toNumber(XEUtils.toFixed(num, count || 0));
  }
  return XEUtils.toNumber(XEUtils.toInteger(num));
}
// 获取url中"?"符后的字串
function getReqParams(url) {
  let urlRet = '';
  const req = {};
  let strs;
  if (url && url.split('?')[1]) {
    // eslint-disable-next-line prefer-destructuring
    urlRet = url.split('?')[1];
  }
  if (urlRet && urlRet.indexOf('?') >= 0) {
    const str = urlRet.substr(1);
    strs = str.split('&');
  } else {
    strs = urlRet.split('&');
  }
  if (strs) {
    for (let i = 0; i < strs.length; i += 1) {
      const temp = strs[i].split('=');
      if (temp.length === 2) {
        req[temp[0]] = decodeURIComponent(temp[1]);
      }
    }
  }
  return req;
}

/**
 *列子 http://localhost:3001/#/WarehouseEnter/index?type=1&id=1
 *返回 {type:1,id:1}
 * @static
 * @param {路径} url
 * @return {*}   截取路径拼接参数 index 从第几个问号开始截取
 * @memberof GlobalMethod
 */
function getUrlParams(url, index = 1) {
  const urlStr = url.split('?')[index];
  const urlSearchParams = new URLSearchParams(urlStr);
  const result = Object.fromEntries(urlSearchParams.entries());
  return result;
}

// 节流函数
function throttle(func, wait) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    if (!timeout) {
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    }
  };
}

export {
  clipboard,
  getAliyunImg,
  getImg,
  getReqParams,
  getStorage,
  getUrlParams,
  isApi,
  numFormat,
  objectToUrlParam,
  sendEvent,
  setStorage,
  setToken,
  throttle,
  toFixed
};
