import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import globalInitState from './globalState';
import { fetchCount } from './globalApi';

export const incrementAsync = createAsyncThunk(
  'counter/fetchCount',
  async amount => {
    const response = await fetchCount(amount);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

const globalSlice = createSlice({
  name: 'global',
  initialState: globalInitState,
  reducers: {
    increment: state => {
      state.value += 1;
    },
    decrement: state => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    }
  },

  extraReducers: builder => {
    builder
      .addCase(incrementAsync.pending, state => {
        state.status = 'loading';
      })
      .addCase(incrementAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value += action.payload;
      });
  }
});

export const { increment, decrement, incrementByAmount } = globalSlice.actions;

export const selectCount = state => state.counter.value;

export const incrementIfOdd = amount => (dispatch, getState) => {
  const currentValue = selectCount(getState());
  if (currentValue % 2 === 1) {
    dispatch(incrementByAmount(amount));
  }
};

export default globalSlice.reducer;
