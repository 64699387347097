import { lazy } from 'react';

import { Route } from 'react-router-dom';
// 人员检测详情
const BiosafetyDetail = lazy(() =>
  import(/* webpackChunkName: "BiosafetyDetail" */ 'src/views/Biosafety/BiosafetyDetail/index')
);

// 审批详情
const ApprovalDetail = lazy(() =>
  import(/* webpackChunkName: "ApprovalDetail" */ 'src/views/Biosafety/ApprovalDetail/index')
);
// 审批记录
const ApprovalHistory = lazy(() =>
  import(/* webpackChunkName: "ApprovalHistory" */ 'src/views/Biosafety/ApprovalHistory/index')
);
const BiosafetyApprovalRouter = {
  '/Biosafety/BiosafetyDetail': BiosafetyDetail,
  '/Biosafety/ApprovalDetail': ApprovalDetail,
  '/Biosafety/ApprovalHistory': ApprovalHistory
};

//  Shop 路由映射关系表
export default function LeavePurchaseApprovalRoute() {
  const keys = Object.keys(BiosafetyApprovalRouter);
  const routeArr = keys.map(key => {
    const Components = BiosafetyApprovalRouter[key];
    return <Route key={key} path={key} element={<Components />} />;
  });
  return routeArr;
}
