import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tag, Button } from 'antd-mobile';
import { decrement, increment } from './store/homeSlice';
import './style/homeStyle.scss';
import { test } from './api';

export default function Home() {
  const count = useSelector(state => state.home.count);
  const dispatch = useDispatch();

  return (
    <div className='container'>
      <div>
        <button type='button' aria-label='Increment value' onClick={() => dispatch(increment())}>
          Increment
        </button>
        <span>{count}</span>
        <button type='button' aria-label='Decrement value' onClick={() => dispatch(decrement())}>
          Decrement
        </button>
        <button
          type='button'
          onClick={async () => {
            const result = await test();
            console.log(result);
          }}
        >
          请求测试
        </button>
        <nav>
          <Link to='/about'>about</Link>
        </nav>
      </div>
      <div className='content'>123</div>
      <Tag color='success'>Primary</Tag>
      <Button
        onClick={() => {
          alert('hello.');
        }}
      >
        Default
      </Button>
    </div>
  );
}
