import { configureStore } from '@reduxjs/toolkit';
import homeReducer from 'src/views/home/store/homeSlice';
import priceReducer from 'src/views/global/pigPrice/store/slice';
import globalReducer from './global/globalSlice';

const store = configureStore({
  reducer: {
    global: globalReducer,
    home: homeReducer,
    price: priceReducer
  }
});

export default store;
