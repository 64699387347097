import axios from 'axios';
import { Toast } from 'antd-mobile';

/**
 * 生成每个请求唯一的键
 * @param {*} config
 * @returns string
 */
function getPendingKey(config) {
  const { url, method, params } = config;
  let { data } = config;
  if (typeof data === 'string') data = JSON.parse(data); // response里面返回的config.data是个字符串对象
  return [url, method, JSON.stringify(params), JSON.stringify(data)].join('&');
}

/**
 * 储存每个请求唯一值, 也就是cancel()方法, 用于取消请求
 * @param {*} config
 */
function addPending(config, pendingMap) {
  const pendingKey = getPendingKey(config);
  config.cancelToken =
    config.cancelToken ||
    new axios.CancelToken(cancel => {
      if (!pendingMap.has(pendingKey)) {
        pendingMap.set(pendingKey, cancel);
      }
    });
}

/**
 * 删除重复的请求
 * @param {*} config
 */
function removePending(config, pendingMap) {
  const pendingKey = getPendingKey(config);
  if (pendingMap.has(pendingKey)) {
    const cancelToken = pendingMap.get(pendingKey);
    cancelToken(pendingKey);
    pendingMap.delete(pendingKey);
  }
}

/**
 * 关闭Loading层实例
 * @param {*} _options
 */
function closeLoading(_options, LoadingInstance) {
  if (_options.loading && LoadingInstance.$count > 0) {
    LoadingInstance.$count -= 1;
  }
  if (LoadingInstance.$count === 0) {
    LoadingInstance.$target.close();
    LoadingInstance.$target = null;
  }
}

/**
 * 处理http异常
 * @param {*} error
 */
function httpErrorStatusHandle(error) {
  // 处理被取消的请求
  if (axios.isCancel(error)) {
    // console.error(`重复请求：${error.message}`);
    return;
  }
  let messageContent = '';
  if (error && error.response) {
    switch (error.response.status) {
      case 302:
        messageContent = '接口重定向！';
        break;
      case 400:
        messageContent = '参数不正确！';
        break;
      case 401:
        messageContent = '您未登录，或者登录已经超时，请先登录！';
        break;
      case 403:
        messageContent = '您没有权限操作！';
        break;
      case 404:
        messageContent = `请求地址出错: ${error.response.config.url}`;
        break; // 在正确域名下
      case 408:
        messageContent = '请求超时！';
        break;
      case 409:
        messageContent = '系统已存在相同数据！';
        break;
      case 500:
        messageContent = '服务器内部错误！';
        break;
      case 501:
        messageContent = '服务未实现！';
        break;
      case 502:
        messageContent = '网关错误！';
        break;
      case 503:
        messageContent = '服务不可用！';
        break;
      case 504:
        messageContent = '服务暂时无法访问，请稍后再试！';
        break;
      case 505:
        messageContent = 'HTTP版本不受支持！';
        break;
      default:
        messageContent = '异常问题，请联系管理员！';
        break;
    }
  }
  if (error.message.includes('timeout')) {
    messageContent = '网络请求超时！';
  }
  if (error.message.includes('Network')) {
    messageContent = window.navigator.onLine ? '服务端异常！' : '您断网了！';
  }

  Toast.show({
    icon: 'fail',
    content: messageContent
  });
}

export { getPendingKey, addPending, removePending, closeLoading, httpErrorStatusHandle };
