import React from 'react';
import { Route } from 'react-router-dom';
import ShopMainRouter from './ShopMainRouter';

const ShopCenterComponents = {
  ...ShopMainRouter
};

//  Shop 路由映射关系表
export default function ShopCenterRoute() {
  const keys = Object.keys(ShopCenterComponents);
  const routeArr = keys.map(key => {
    const Components = ShopCenterComponents[key];
    return <Route key={key} path={key} element={<Components />} />
  })
  return routeArr;
}
