import { lazy } from 'react';

import { Route } from 'react-router-dom';
// 车辆检测详情
const VehicleSafetyDetail = lazy(() =>
  import(
    /* webpackChunkName: "VehicleSafetyDetail" */ 'src/views/VehicleSafety/VehicleSafetyDetail/index'
  )
);

// 审批详情
const ApprovalDetail = lazy(() =>
  import(/* webpackChunkName: "ApprovalDetail" */ 'src/views/VehicleSafety/ApprovalDetail/index')
);
// 审批记录
const ApprovalHistory = lazy(() =>
  import(/* webpackChunkName: "ApprovalHistory" */ 'src/views/VehicleSafety/ApprovalHistory/index')
);
const VehicleSafetyApprovalRouter = {
  '/VehicleSafety/VehicleSafetyDetail': VehicleSafetyDetail,
  '/VehicleSafety/ApprovalDetail': ApprovalDetail,
  '/VehicleSafety/ApprovalHistory': ApprovalHistory
};

//  Shop 路由映射关系表
export default function VehicleSafetyRoute() {
  const keys = Object.keys(VehicleSafetyApprovalRouter);
  const routeArr = keys.map(key => {
    const Components = VehicleSafetyApprovalRouter[key];
    return <Route key={key} path={key} element={<Components />} />;
  });
  return routeArr;
}
