import { lazy } from 'react';

import { Route } from 'react-router-dom';
// 进销存-采购审批

//! 以下是h5页面
const LeavePurchaseApprovalWechatDetail = lazy(() =>
  import(
    /* webpackChunkName: "LeavePurchaseApprovalWechatDetail" */ 'src/views/LeaveApproval/components/WechatDetail/index'
  )
);
const LeavePurchaseApprovalWechatApprovalList = lazy(() =>
  import(
    /* webpackChunkName: "LeavePurchaseApprovalWechatApprovalList" */ 'src/views/LeaveApproval/components/ApprovalList/index'
  )
);
const LeavePurchaseApprovalWechatApprovalHistory = lazy(() =>
  import(
    /* webpackChunkName: "LeavePurchaseApprovalWechatApprovalList" */ 'src/views/LeaveApproval/components/History'
  )
);
const LeavePurchaseApprovalRouter = {
  '/LeavePurchaseApprovals/WechatDetail': LeavePurchaseApprovalWechatDetail,
  '/LeavePurchaseApprovals/ApprovalList': LeavePurchaseApprovalWechatApprovalList,
  '/LeavePurchaseApprovals/ApprovalList/history': LeavePurchaseApprovalWechatApprovalHistory
};

//  Shop 路由映射关系表
export default function LeavePurchaseApprovalRoute() {
  const keys = Object.keys(LeavePurchaseApprovalRouter);
  const routeArr = keys.map(key => {
    const Components = LeavePurchaseApprovalRouter[key];
    return <Route key={key} path={key} element={<Components />} />;
  });
  return routeArr;
}
