// export const BASEURL = 'http://113.13.246.31:3101';
export const BASEURL = process.env.HUMU_BASE_URL;
// export const BASEURL = 'http://www.humuyun.com:3100';
export const TIMEOUT = process.env.HUMU_TIME_OUT;
/**
 * 官网
 */
export const WEBSITE = process.env.HUMU_WEB_SITE;
/**
 * 互牧云3.0官网
 */
export const WEBSITEPig = process.env.HUMU_WEB_SITE_Pig;
/**
 * 公猪站管理系统官网
 */
export const WEBSITEBoar = process.env.HUMU_WEB_SITE_Boar;
/**
 * 通用版APP下载地址
 */
export const HMYAPPDOWN = process.env.HUMU_HMY_APP_DOWN;
export default {};
