import { createSlice } from '@reduxjs/toolkit';
import homeInitState from './homeState';

export const homeSlice = createSlice({
  name: 'home',
  initialState: homeInitState,
  reducers: {
    increment: state => {
      state.count += 1;
    },
    decrement: state => {
      state.count -= 1;
    },
    incrementByAmount: (state, action) => {
      state.count += action.payload;
    }
  }
});

export const { increment, decrement, incrementByAmount } = homeSlice.actions;

export default homeSlice.reducer;
