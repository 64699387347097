import { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Home from 'src/views/home/home'; // 非路由懒加载组件

// 商城路由配置
import Biosafety from './Biosafety'; //人员生物安全
import VehicleSafety from './VehicleSafety'; //车辆生物安全

import LeavePurchaseApprovalRoute from './LeavePurchaseApprovalRoute/index';
import ShopCenterRoute from './ShopRouter';

const MimeLazy = lazy(() => import(/* webpackChunkName: "mime" */ 'src/views/mime')); // 路由懒加载组件
// 加入部门H5页面
const JoinDepartment = lazy(() =>
  import(/* webpackChunkName: "joinDepartment" */ 'src/views/global/joinDepartment')
); // 路由懒加载组件
/**
 * 今日猪价
 */
const PigPrice = lazy(() => import(/* webpackChunkName: "PigPrice" */ 'src/views/global/pigPrice'));
/**
 * 生产诊断公示
 */
const GroupDiagnosis = lazy(() =>
  import(/* webpackChunkName: "GroupDiagnosis" */ 'src/views/global/groupDiagnosis')
);
/**
 * 生产诊断公示详细
 */
const GroupDiagnosisDetailed = lazy(() =>
  import(
    /* webpackChunkName: "GroupDiagnosisDetailed" */ 'src/views/global/groupDiagnosis/detailed'
  )
);

// 隐私协议
const Privacy = lazy(() => import(/* webpackChunkName: "privacy" */ 'src/views/global/privacy')); // 路由懒加载组件
// 用户协议
const UserProtocol = lazy(() =>
  import(/* webpackChunkName: "privacy" */ 'src/views/global/UserProtocol')
); // 路由懒加载组件
/**
 * 稀释详情页面
 */
const Attenuant = lazy(() => import('src/views/global/Attenuant/attenuant'));
/**
 * 种猪视频/图片
 */
const VideoImageUpload = lazy(() =>
  import(/* webpackChunkName: "videoImageUpload" */ 'src/views/videoImageUpload')
);

// 猪精订单物流详情信息
const SpermOrderDetail = lazy(() =>
  import(/* webpackChunkName: "SpermOrderDetail" */ 'src/views/global/spermOrderDetail')
);
// 日报
const ReportDay = lazy(() => import('src/views/ReportDay/index'));
// 公猪站日报
const BoarDaily = lazy(() => import(/* webpackChunkName: "daily" */ 'src/views/boar/daily'));
// 加入公猪站经销商
const JoinBoarDepartmentDealer = lazy(() =>
  import(
    /* webpackChunkName: "JoinBoarDepartmentDealer" */ 'src/views/global/JoinBoarDepartmentDealer'
  )
);
// 支付购买列表
const Pay = lazy(() => import(/* webpackChunkName: "Pay" */ 'src/views/Pay'));
// 支付购买成功
const PurchaseSuccessful = lazy(() =>
  import(/* webpackChunkName: "PurchaseSuccessful" */ 'src/views/Pay/PurchaseSuccessful')
);
// 支付咨询
const Consultant = lazy(() =>
  import(/* webpackChunkName: "Consultant" */ 'src/views/Pay/Consultant')
);
// 支付咨询提交成功
const SubmitSuccessful = lazy(() =>
  import(/* webpackChunkName: "SubmitSuccessful" */ 'src/views/Pay/SubmitSuccessful')
);
// 支付版本对比
const Version = lazy(() => import(/* webpackChunkName: "Version" */ 'src/views/Pay/Version'));
// 支付购买
const Purchase = lazy(() => import(/* webpackChunkName: "Purchase" */ 'src/views/Pay/Purchase'));

// 公猪站支付购买列表
const PayBoar = lazy(() => import(/* webpackChunkName: "PayBoar" */ 'src/views/Pay/Boar'));
// 公猪站支付咨询
const ConsultantBoar = lazy(() =>
  import(/* webpackChunkName: "ConsultantBoar" */ 'src/views/Pay/Boar/Consultant')
);
// 公猪站支付咨询提交成功
const SubmitSuccessfulBoar = lazy(() =>
  import(/* webpackChunkName: "SubmitSuccessfulBoar" */ 'src/views/Pay/Boar/SubmitSuccessful')
);
// 公猪站支付版本对比
const VersionBoar = lazy(() =>
  import(/* webpackChunkName: "VersionBoar" */ 'src/views/Pay/Boar/Version')
);
// 公猪站支付购买
const PurchaseBoar = lazy(() =>
  import(/* webpackChunkName: "PurchaseBoar" */ 'src/views/Pay/Boar/Purchase')
);
// 网页授权
const OAuth = lazy(() => import(/* webpackChunkName: "OAuth" */ 'src/views/Pay/OAuth'));
// 网页授权回调
const OAuthCodeBack = lazy(() =>
  import(/* webpackChunkName: "OAuthCodeBack" */ 'src/views/Pay/OAuth/OAuthCodeBack')
);

export default function ProjectRouter() {
  return (
    // 路由懒加载中渲染fallback指定的组件
    <Suspense fallback={null}>
      <Routes>
        {/* 重定向 */}
        <Route path='/' element={<Navigate replace to='/home' />} />
        <Route path='/home' element={<Home />} />
        <Route path='/mime' element={<MimeLazy />} />;
        <Route path='/todo' element={<div>todo</div>} />
        <Route path='/message' element={<div>message</div>} />
        <Route path='/JoinDepartment' element={<JoinDepartment />} />
        <Route path='/PigPrice' element={<PigPrice />} />
        <Route path='/GroupDiagnosis' element={<GroupDiagnosis />} />
        <Route path='/DiagnosisDetailed' element={<GroupDiagnosisDetailed />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/UserProtocol' element={<UserProtocol />} />
        <Route path='/SpermOrderDetail' element={<SpermOrderDetail />} />
        <Route path='/Attenuant/:id/' element={<Attenuant />} />
        <Route path='/Attenuant/:id/:detailId' element={<Attenuant />} />
        <Route path='/VideoImageUpload' element={<VideoImageUpload />} />
        <Route path='/JoinBoarDepartmentDealer/:id' element={<JoinBoarDepartmentDealer />} />
        <Route path='/Report/:nDepartmentID/:tDate' element={<ReportDay />} />
        <Route path='/BoarDaily/:id/:type/:time' element={<BoarDaily />} />
        <Route path='/Pay' element={<Pay />} />
        <Route path='/PurchaseSuccessful' element={<PurchaseSuccessful />} />
        <Route path='/SubmitSuccessful' element={<SubmitSuccessful />} />
        <Route path='/Consultant' element={<Consultant />} />
        <Route path='/Version' element={<Version />} />
        <Route path='/Purchase' element={<Purchase />} />
        <Route path='/PayBoar' element={<PayBoar />} />
        <Route path='/SubmitSuccessfulBoar' element={<SubmitSuccessfulBoar />} />
        <Route path='/ConsultantBoar' element={<ConsultantBoar />} />
        <Route path='/VersionBoar' element={<VersionBoar />} />
        <Route path='/PurchaseBoar' element={<PurchaseBoar />} />
        {/* 商城路由配置 */}
        {ShopCenterRoute()}
        {/* 出库审批 */}
        {LeavePurchaseApprovalRoute()}
        {/* 人员检测 */}
        {Biosafety()}
        {/* 车辆检测 */}
        {VehicleSafety()}
        <Route path='/OAuth' element={<OAuth />} />
        <Route path='/OAuthCodeBack' element={<OAuthCodeBack />} />
      </Routes>
    </Suspense>
  );
}
