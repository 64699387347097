import request from 'src/service/request';

export function fetchCount(amount = 1) {
  return new Promise(resolve => {
    setTimeout(() => resolve({ data: amount }), 500);
  });
}
/**
 * 登录
 * @param {*} cAccount 账号
 * @param {*} cPwd 密码
 * @returns
 */
export const getAppToken = (cAccount, cPwd) =>
  request({
    url: `/UserMgrPublicApi/AppToken`,
    method: 'post',
    data: { cAppID: 'Humuyun', cAccount, cPwd },
    options: {
      loading: true
    }
  });
/**
 * 切换部门
 * @param {*} cAccount 账号
 * @param {*} cPwd 密码
 * @param {*} nDepartmentID 部门
 * @returns
 */
export const SwitchDep = (cAccount, cPwd, nDepartmentID) =>
  request({
    url: `/UserMgrPublicApi/AppToken/SwitchDep`,
    method: 'post',
    data: { cAppID: 'Humuyun', cAccount, cPwd, nDepartmentID },
    options: {
      loading: true
    }
  });

export default {};
